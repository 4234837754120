import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'

import { shortenScorePanelRowName, toFixed1IfDecimal } from '../../../../../va-corejs-v3/utils'

import '../styles.scss'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

export class Single extends React.Component {
  render() {
    const { scorePanel, maxProductScore, size, environment } = this.props

    function drawScorePanel() {
      const html = []
      if (scorePanel && scorePanel.length > 0) {
        scorePanel.map((row, rowIndex) => {
          row.value = row.value > maxProductScore ? maxProductScore : row.value
          const percentageWidht = (row.value * 100) / maxProductScore
          const percentageMargin = 100 - percentageWidht
          const itemStyle = {
            width: `${percentageWidht}%`,
            marginLeft: 0,
            marginRight: `${percentageMargin}%`,
            textAlign: 'right',
          }

          html.push(
            <tr key={uuidv4()}>
              <td className="score_panel_first_column label">{shortenScorePanelRowName(row.name)}</td>
              <td
                key={uuidv4()}
                className={classNames({
                  size_compact: size === 'compact',
                })}
              >
                {percentageWidht > 0 && (
                  <div
                    className={classNames({
                      score_panel_bar_container: true,
                    })}
                  >
                    <div
                      className={classNames({
                        score_panel_bar_value: true,
                        row_1: rowIndex === 0,
                        row_2: rowIndex === 1,
                        row_3: rowIndex === 2,
                      })}
                      style={itemStyle}
                    >
                      <div
                        className={classNames({
                          score_panel_bar_value_text: true,
                          size_compact: size === 'compact',
                        })}
                      >
                        {toFixed1IfDecimal(row.value)}
                      </div>
                    </div>
                  </div>
                )}
              </td>
              <td className="last_column">
                {rowIndex === 1 && <div>{`/ ${environment.config.max_product_score}`}</div>}
              </td>
            </tr>
          )
          return row
        })
      }
      return html
    }

    return (
      <div className="single_score_panel_container">
        <table
          className={classNames({
            score_panel_table: true,
            size_compact: size === 'compact',
          })}
        >
          <tbody>{drawScorePanel()}</tbody>
        </table>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    environment: state.environment,
    device: state.device,
    scoringTree: state.scoringTree,
    comparisonBoard: state.comparisonBoard,
  }
}

export default connect(mapStateToProps, actionCreators)(Single)
