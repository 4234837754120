import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'

import '../styles.scss'

import { toFixed1IfDecimal } from '../../../../../va-corejs-v3/utils'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

export class One extends React.Component {
  render() {
    const { scorePanel, maxProductScore, scoreKey, scoreData } = this.props
    function drawScorePanel() {
      const html = []

      if (scorePanel && (scorePanel[scoreKey] || scoreData)) {
        const { name, values } = scorePanel[scoreKey] ? scorePanel[parseInt(scoreKey, 10)] : scoreData

        html.push(
          <div key={uuidv4()}>
            <div className="score_panel_first_column label left">{name}</div>

            {values &&
              values.map((item, index) => {
                item.value = item.value > maxProductScore ? maxProductScore : item.value
                const percentageWidht = (item.value * 100) / maxProductScore
                const percentageMargin = 100 - percentageWidht
                const itemStyle = {
                  width: `${percentageWidht}%`,
                  marginRight: `${percentageMargin}%`,
                  textAlign: 'left',
                }
                return (
                  <div key={uuidv4()} className="score_panel_row">
                    <div className="label">
                      <div className="text">{item.name.en}</div>
                    </div>

                    {percentageWidht > 0 && (
                      <div className="score_panel_bar_container">
                        <div
                          className={classNames({
                            score_panel_bar_value: true,
                            left: index % 2 === 0,
                            right: index % 2 !== 0,
                            exterior: name.indexOf('Exterior') === 0,
                            interior: name.indexOf('Interior') === 0,
                            cargo: name.indexOf('Cargo') === 0,
                            digital: name.indexOf('Digital') === 0,
                          })}
                          style={itemStyle}
                        >
                          <div className="score_panel_bar_value_text">{toFixed1IfDecimal(item.value)}</div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
          </div>
        )
      }
      return html
    }

    return (
      <div className="one_score_panel_container">
        <div className="score_panel_table">{drawScorePanel()}</div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    device: state.device,
    scoringTree: state.scoringTree,
    comparisonBoard: state.comparisonBoard,
  }
}

export default connect(mapStateToProps, actionCreators)(One)
