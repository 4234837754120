import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'

import '../styles.scss'

import {
  shortenScorePanelRowName,
  shortenScorePanelColName,
  toFixed1IfDecimal,
} from '../../../../../va-corejs-v3/utils'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

export class Double extends React.Component {
  render() {
    const { scorePanel, maxProductScore, size } = this.props
    function drawScorePanel() {
      const html = []

      if (scorePanel && scorePanel.length > 0) {
        // Draw column names
        const firstRow = scorePanel[0]
        html.push(
          <tr key={0}>
            <td />
            {firstRow.values &&
              firstRow.values.map(value => {
                const name = value.name.en.toUpperCase()
                return (
                  <td className={classNames({ label: true })} key={uuidv4()}>
                    <div className="text">{size === 'compact' ? shortenScorePanelColName(name) : name}</div>
                  </td>
                )
              })}
          </tr>
        )

        // Draw data
        scorePanel.map(row => {
          const { name } = row
          html.push(
            <tr key={uuidv4()}>
              <td className="score_panel_first_column label left">{shortenScorePanelRowName(row.name)}</td>
              {row.values &&
                row.values.map((item, index) => {
                  item.value = item.value > maxProductScore ? maxProductScore : item.value
                  const percentageWidht = (item.value * 100) / maxProductScore
                  const percentageMargin = 100 - percentageWidht
                  const itemStyle = {
                    width: `${percentageWidht}%`,
                    marginLeft: index % 2 ? 0 : `${percentageMargin}%`,
                    marginRight: index % 2 ? `${percentageMargin}%` : 0,
                    textAlign: index % 2 ? 'left' : 'right',
                  }
                  return (
                    <td
                      key={uuidv4()}
                      className={classNames({
                        size_compact: size === 'compact',
                        size_mashed: size === 'mashed',
                      })}
                    >
                      {percentageWidht > 0 && (
                        <div className="score_panel_bar_container">
                          <div
                            className={classNames({
                              score_panel_bar_value: true,
                              left: index % 2 === 0,
                              right: index % 2 !== 0,
                              exterior: name.indexOf('Exterior') === 0,
                              interior: name.indexOf('Interior') === 0,
                              cargo: name.indexOf('Cargo') === 0,
                              digital: name.indexOf('Digital') === 0,
                            })}
                            style={itemStyle}
                          >
                            <div
                              className={classNames({
                                score_panel_bar_value_text: true,
                                size_compact: size === 'compact',
                                size_mashed: size === 'mashed',
                              })}
                            >
                              {toFixed1IfDecimal(item.value)}
                            </div>
                          </div>
                        </div>
                      )}
                    </td>
                  )
                })}
            </tr>
          )
          return row
        })
      }
      return html
    }

    return (
      <div className="double_score_panel_container">
        <table
          className={classNames({
            score_panel_table: true,
            size_compact: size === 'compact',
            size_mashed: size === 'mashed',
          })}
        >
          <tbody>{drawScorePanel()}</tbody>
        </table>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    device: state.device,
    scoringTree: state.scoringTree,
    comparisonBoard: state.comparisonBoard,
  }
}

export default connect(mapStateToProps, actionCreators)(Double)
