import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../store/actions'

import Single from './_parts/single'
import Double from './_parts/double'
import DoubleProject from './_parts/doubleProject'
import SingleProject from './_parts/singleProject'
import One from './_parts/one'

export class ScorePanel extends React.Component {
  render() {
    const { scorePanel, maxProductScore, size, scoreKey, projectMode, scoreData, type } = this.props
    const isProjectMode = projectMode && scorePanel.some(row => typeof row.forecast !== 'undefined')

    if (isProjectMode && type === 'double') {
      return <DoubleProject scorePanel={scorePanel} maxProductScore={maxProductScore} size={size} />
    }

    if (isProjectMode && type === 'single') {
      return <SingleProject scorePanel={scorePanel} maxProductScore={maxProductScore} size={size} />
    }

    if (type === 'single') {
      return <Single scorePanel={scorePanel} maxProductScore={maxProductScore} size={size} />
    }

    if (typeof scoreKey !== 'undefined' || typeof scoreData !== 'undefined') {
      return (
        <One
          scorePanel={scorePanel}
          maxProductScore={maxProductScore}
          size={size}
          scoreKey={scoreKey}
          scoreData={scoreData}
        />
      )
    }

    return <Double scorePanel={scorePanel} maxProductScore={maxProductScore} size={size} />
  }
}

ScorePanel.defaultProps = {
  type: process.env.scorePanelType,
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    device: state.device,
    scoringTree: state.scoringTree,
    comparisonBoard: state.comparisonBoard,
  }
}

export default connect(mapStateToProps, actionCreators)(ScorePanel)
