import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'

import { shortenScorePanelRowName, toFixed1IfDecimal } from '../../../../../va-corejs-v3/utils'

import '../styles.scss'
import nodeDefinitionTypeMap from '../../../scoring_tree/helper/nodeDefinitionTypeMap'

const classNames = require('classnames')
const uuidv4 = require('uuid/v4')

export class DoubleProject extends React.Component {
  /*-----------------------------------------------------*/
  // COMPLETED PERCENTAGE
  // calculate scored nodes rate on all enabled nodes
  /*-----------------------------------------------------*/

  calcCompletedPercentage = scoreSet => {
    const { scoringTree } = this.props
    const { nodeDefsObj, scoring } = scoringTree
    const { nodes } = scoring

    const nodeIds = Object.keys(nodeDefsObj)

    const totEnabledCriterion = nodeIds.filter(id => {
      if (scoreSet)
        return (
          (nodes[id][scoreSet].is_enabled || typeof nodes[id][scoreSet].is_enabled === 'undefined') &&
          nodeDefsObj[id].type === nodeDefinitionTypeMap.criterion
        )
      return nodes[id].is_enabled && nodeDefsObj[id].type === nodeDefinitionTypeMap.criterion
    }).length

    const completedCriterion = nodeIds.filter(id => {
      if (scoreSet)
        return (
          nodes[id][scoreSet].is_enabled &&
          typeof nodes[id][scoreSet].is_default !== 'undefined' &&
          !nodes[id][scoreSet].is_default
        )
      return nodes[id].is_enabled && !nodes[id].is_default
    }).length

    const completed = (completedCriterion / totEnabledCriterion) * 100

    return Math.round(completed)
  }

  render() {
    const { scorePanel, scoringTree, maxProductScore, size, environment, texts } = this.props

    function drawScorePanel(side, scoreSet) {
      const html = []
      if (scorePanel && scorePanel.length > 0) {
        scorePanel.map((row, rowIndex) => {
          if (scoreSet) row = row[scoreSet]
          if (scoreSet && !row) return false

          row.value = row.value > maxProductScore ? maxProductScore : row.value
          const percentageWidht = (row.value * 100) / maxProductScore
          const percentageMargin = 100 - percentageWidht
          const itemStyle = {
            width: `${percentageWidht}%`,
            marginLeft: 0,
            marginRight: 0,
            textAlign: side,
          }

          itemStyle[`margin${side.charAt(0).toUpperCase() + side.slice(1)}`] = `${percentageMargin}%`

          html.push(
            <tr key={uuidv4()}>
              {side === 'left' && (
                <td className="score_panel_first_column label">{shortenScorePanelRowName(row.name)}</td>
              )}
              <td
                key={uuidv4()}
                className={classNames({
                  size_compact: size === 'compact',
                })}
              >
                {percentageWidht > 0 && (
                  <div
                    className={classNames({
                      score_panel_bar_container: true,
                    })}
                  >
                    <div
                      className={classNames({
                        score_panel_bar_value: true,
                        row_1: rowIndex === 0,
                        row_2: rowIndex === 1,
                        row_3: rowIndex === 2,
                      })}
                      style={itemStyle}
                    >
                      <div
                        className={classNames({
                          score_panel_bar_value_text: true,
                          size_compact: size === 'compact',
                        })}
                      >
                        {toFixed1IfDecimal(row.value)}
                      </div>
                    </div>
                  </div>
                )}
              </td>
              {side === 'right' && (
                <td className="last_column">
                  {rowIndex === 1 && <div>{`/ ${environment.config.max_product_score}`}</div>}
                </td>
              )}
            </tr>
          )
          return row
        })
      }
      return html
    }

    return (
      <div className="single_score_panel_container double_score_panel_project ">
        <div className="double_score_panel_project_inner">
          <table
            className={classNames({
              score_panel_table: true,
              size_compact: size === 'compact',
              score_panel_left: true,
            })}
          >
            <thead>
              <tr>
                <th />
                <th>
                  {texts.score}
                  {scoringTree.completedPercentage > 0 && (
                    <span className="double_score_panel_project_completed">- {this.calcCompletedPercentage()}%</span>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>{drawScorePanel('left')}</tbody>
          </table>
          <table
            className={classNames({
              score_panel_table: true,
              size_compact: size === 'compact',
              score_panel_right: true,
            })}
          >
            <thead>
              <tr>
                <th>
                  {texts.forecast}
                  {scoringTree.completedPercentage > 0 && (
                    <span className="double_score_panel_project_completed">
                      - {this.calcCompletedPercentage('forecast')}%
                    </span>
                  )}
                </th>
                <th />
              </tr>
            </thead>

            <tbody>{drawScorePanel('right', 'forecast')}</tbody>
          </table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    scoringTree: state.scoringTree,
    comparisonBoard: state.comparisonBoard,
  }
}

export default connect(mapStateToProps, actionCreators)(DoubleProject)
